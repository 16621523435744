.c-footer {
    display: -ms-grid;
    display: grid;
    background: $co-green;
    color: $co-quaternary;
    padding: 3rem 1.5rem;
    text-align: center;

    &__name {
        @include media-breakpoint-down(sm) {
            grid-row: 2;
            grid-column: 3/5;
            width: 100%;

            &:first-child {
                grid-column: 1/3;
                border-right: 1px solid $co-white;
            }
        }

        @include media-breakpoint-down(xs) {
            grid-row: 3;
            grid-column: 1/5;

            &:first-child {
                border: none;
                grid-row: 2;
                grid-column: 1/5;
            }
        }
    }

    &__phone {
        font-weight: $fw-extra-light;
    }

    &__logo {
        height: 4rem;

        @include media-breakpoint-down(sm) {
            grid-row: 1;
            grid-column: 1/5;
        }
    }
    
    &__iban {
        font-weight: $fw-extra-light;
        grid-column: 1/5;
        color: $co-tertiary;

        p {
            display: inline-flex;
            gap: 0.4rem;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include media-breakpoint-down(sm) {
            justify-self: center;
            text-align: center;
            grid-row: 3;
        }

        @include media-breakpoint-down(xs) {
            grid-row: 4;
        }
    }

    .o-container {
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(3, 1fr);
        row-gap: 1.5rem;

        @include media-breakpoint-down(sm) {
            grid-template-rows: repeat(3, auto);
            grid-template-columns: repeat(4, 1fr);
        }
    }
}