.m-blob {
    background: $co-white;
    border-radius: 50%;
    box-shadow: 0 0 0 0 $co-white;
    margin: 10px;
    margin-right: 15px;
    height: 20px;
    width: 20px;
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($co-white, 0.7);
    }
    
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba($co-white, 0);
    }
    
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($co-white, 0);
    }
}

@keyframes pulse-menu {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($co-red, 0.7);
    }
    
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba($co-red, 0);
    }
    
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($co-red, 0);
    }
}