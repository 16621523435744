.m-language {
    @include transition;
    position: absolute;
    overflow: hidden;
    width: rem(42);
    height: rem(38);
    z-index: 10000;

    &--open {
        height: rem(76);

        @include media-breakpoint-down(sm) {
            height: rem(76);
        }
    }

    &--closed {
        @include transition(none);
    }

    &--selected {
        cursor: default;
        pointer-events: none;
        background-color: $co-green-2;

        &:hover {
            opacity: 1;
            transition: none;
        }
    }

    @include media-breakpoint-down(sm) {
        position: fixed;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
    }
}