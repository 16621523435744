.c-music {
    margin-top: 44px;
    width: 100%;

    &__header {
        background: $co-green-3;
        display: -ms-grid;
        display: grid;
        grid-template-columns: 1.5fr 2fr;
        gap: 2rem;
        align-content: end;
        padding: 3rem 2rem 0;

        &--info {
            margin: 1rem 0 2rem;
            max-width: 600px;
            align-self: center;
        }

        @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr 1fr;
            padding-top: 1rem;
            padding-left: 0;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
            text-align: center;
            justify-items: center;
            padding-left: 2rem;
        }
    }

    &__image {
        max-height: 465px;
        max-width: 664px;
        width: 100%;
        align-self: self-end;
        justify-self: center;

        @include media-breakpoint-down(md) {
            grid-row: 2;
            max-height: 358px;
            max-width: 511px;
        }
    }

    &__title {
        font-family: $ff-secondary;
        font-size: 3rem;
        color: $co-green-2;
        display: -ms-grid;
        display: grid;

        @include media-breakpoint-down(lg) {
            font-size: 2.5rem;
        }
    }

    &__title-2 {
        width: 100%;
        color: $co-green;
    }
    
    &__description {
        padding-top: 1rem;

        &--ranking {
            margin-top: 2rem;
        
            @include media-breakpoint-down(md) {
                margin: 2rem auto 0;
            }
        }
    }
    
    &__content {
        @include transition;
        padding: 1.5rem;
        width: 100%;
        background: $co-green-3;

        &.is-empty {
            height: 0;
            padding: 0;

            &.is-loading {
                height: 120px;
            }
        }
    }

    &__search {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 4fr 1fr;
    }

    &__input {
        margin: 1.3rem 1rem 0;
        width: calc(100% - 2rem);
    }

    &__submit {
        width: 100%;
    }

    &__tracks {
        display: -ms-grid;
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(6, 1fr);
        
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(5, 1fr);
        }
        
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(4, 1fr);
        }
        
        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(3, 1fr);
        }
        
        @include media-breakpoint-down(xs) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__track {
        $this: &;

        &--album {
            position: relative;
            display: grid;
            overflow: hidden;
            border-radius: rem(5);
        }
        
        &--portrait {
            width: 100%;
            border-top-right-radius: 1rem;
        }

        &--play-overlay {
            @include transition;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $co-green;
            background: linear-gradient(0deg, rgba($co-green, 1) 0%, rgba($co-green, 0) 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 0;
        }

        &--play-icon {
            @include transition;
            width: 40px;
            height: 40px;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            position: absolute;
            cursor: pointer;
            opacity: 0;
            border-radius: 50%;
    
            img {
                max-width: 100%;
            }

            &:hover {
                background: rgba($co-white,0.4);
            }
        }

        &--info {
            font-size: 0.8rem;
        }

        &--title {
            margin-top: 0.4rem;
            font-size: rem(14);

            button {
                cursor: pointer;
                text-align: left;
                border: 0;
                padding: 0;
                color: $co-green;
                font-weight: $fw-bold;

                &.no-preview {
                    cursor: default;
                }
            }
        }
        
        &--artists {
            margin-top: 0.4rem;
            font-weight: $fw-extra-light;
            font-size: rem(12);
        }

        &--vote {
            width: 100%;
            background: $co-green-2;
            margin-top: 0.4rem;
            padding: 0.4rem 0;

            &.is-loading {
                height: 30.99px;

                &::after {
                    height: 30px;
                    width: 30px;
                }
            }
        }

        &--votes {
            display: inline-flex;
            position: absolute;
            top: 0;
            right: 0;
            color: $co-white;
            padding: 5px 10px;
            background: $co-green-2;
            border-bottom-left-radius: rem(5);
            font-size: 11px;
            z-index: 2;
        }

        &--votes-icon {
            width: 14px !important;
            height: 14px !important;
            margin-right: 3px;
        }

        &:hover {
            #{$this}--play-overlay {
                opacity: 1;
            }

            #{$this}--play-icon {
                opacity: 1;
            }

            #{$this}--title button {
                color: $co-green-2;

                &.no-preview {
                    color: $co-green;
                }
            }
        }
    }

    &.is-playing {
        padding-bottom: rem(75);
    
        .c-player {
            display: -ms-grid;
            display: grid;
        }
    }
}