.m-logo {

    $this: &;
    line-height: 0;
    display: block;
    max-width: 100%;
    @include transition;
    
    &--landing {
        width: 100%;

        #{$this}__image {
            max-width: $landing-m-logo-width;

            @include media-breakpoint-down(lg) {
                max-width: $landing-m-logo-width-md;
            }
            @include media-breakpoint-down(sm) {
                max-width: $landing-m-logo-width-sm;
            }
        }
    }
}