.c-modal {
    $this: &;

    width: 50%;
    max-width: 500px;
    margin: auto;
    
    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: $co-white;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        outline: 0;
        box-shadow: 0 0px 9px rgb(0 0 0 / 30%);
        animation-name: modal-anim;
        animation-duration: .25s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
    }

    &__header {
        position: relative;
        top: 0;
        min-height: 1rem;
        padding: 1rem 2.5rem;
        text-align: center;
    }

    &__close {
        padding: 0;
        font-size: 1.5rem;
        line-height: 1rem;
        float: right;
        opacity: 0.6;
        border: 0;
        outline: 0;
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 1;
        
        &:hover {
            opacity: 0.3;
            cursor: pointer;
        }
    }

    &__title {
        margin: rem(30) 0 0;
        font-weight: 500;
        font-size: 2.3rem;
        font-family: $ff-secondary;
        color: $co-primary;
    }

    &__body {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
        max-height: 80vh;
        overflow-y: auto;
        min-height: 100px;
        padding: 1rem 2.5rem;
        color: $co-grey-2;
        text-align: center;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        border-top: 1px solid $co-border;
    }

    &.MuiModal-root {
        position: absolute;
        bottom: auto;
    }

    @include media-breakpoint-down(md) {
        width: 75%;
    }

    @include media-breakpoint-down(sm) {
        width: 90%;
    }
}

@keyframes modal-anim {
    from {
        margin-top: -50px;
        opacity: 0;
    }
    to {
        margin-top: 50px;
        opacity: 1;
    }
}
