.c-menu {
    $this: &;
    
    @include transition;
    display: -ms-grid;
    display: grid;
    justify-items: center;
    align-items: center;
    position: fixed;
    width: 100%;
    background: white;
    height: 44px;
    box-shadow: 0px 0px 12px 0px;
    top: 0;
    z-index: 4;

    &__logo {
        height: 35px;

        &-image {
            height: 100%;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    &__button {
        justify-self: start;
        fill: $co-green !important;

        &:hover {
            cursor: pointer;
            fill: $co-green-2 !important;
        }
    }

    &__language {
        justify-self: end;
        text-transform: uppercase;
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.5rem;

        .m-language--selected {
            background: none;
            font-weight: bold;
            color: $co-green-2;
            border-bottom: 1px solid;
        }

        span {
            cursor: pointer;

            @include hover;
        }
    }

    &__nav {
        @include transition;
        display: -ms-grid;
        display: grid;
        width: 35vw;
        height: calc(100vh - 44px);
        justify-items: center;
        align-items: center;
        background-color: rgba(darken($co-green, 10%), 0.9);
        position: fixed;
        left: -35vw;
        top: 44px;
        z-index: 11;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
        padding-bottom: 44px;

        @include media-breakpoint-down(sm) {
            width: 100vw;
            left: -100vw;
        }
    }

    &__out {
        position: absolute;
        display: none;
        top: 44px;
        width: 100vw;
        height: 100vh;
    }

    &__list {
        display: -ms-grid;
        display: grid;
        align-items: center;
        justify-items: center;
        text-align: center;
        height: 45vh;

        &.is-admin {
            height: 60vh;
        }
    }

    &__item {
        display: inline-flex;
        column-gap: 0.5rem;
        color: $co-white;

        &.is-hacker {
            color: $co-red;

            #{$this}__link {
                color: $co-red;
            }
        }
    }

    &__link {
        @include hover;
        text-decoration: none;
        color: $co-white;
        cursor: pointer;

        &:hover {
            color: $co-white;
        }
    }

    &__divider {
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 0.5rem 0;
        margin: 1rem 0 0;
        text-transform: uppercase;
        width: 100%;
        justify-content: center;
    }

    &--visible {
        opacity: 1 !important;
    }

    &--open {
        #{$this}__nav {
            left: 0;
        }
        #{$this}__out {
            display: block;
        }
    }

    .o-container {
        grid-template-columns: 1fr 2fr 1fr;
    }

    .m-blob {
        margin: 0 0 0 10px;
        background: $co-red;
        animation: pulse-menu 2s infinite;
    }
}