.m-divider {
    height: calc(100vh + 1px);
    width: 10vh;
    fill: $co-white;
    margin-left: -1px;

    @include media-breakpoint-down(sm) {
        height: 10vw;
        width: 100%;
        margin-top: -1px;
        margin-left: 0;
        top: 0;
    }
}