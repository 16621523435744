.c-home {
    display: -ms-grid;
    display: grid;
    justify-items: center;

    &__title {
        font-family: $ff-tertiary;
        font-size: 3rem;
        font-weight: bold;
        color: $co-primary;
        text-align: center;
        margin: rem(48) 0;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: rem(25);
            background: url('#{$imagesUrl}/images/line.svg') no-repeat center;
            margin: rem(12) 0 0;
        }
    }

    .c-menu {
        opacity: 0;
    }
}