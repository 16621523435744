.c-ranking {
    $this: &;

    &__content {
        padding: 1.5rem;
        width: 100%;
    }
    
    &__title {
        display: -ms-grid;
        display: grid;
        font-family: $ff-secondary;
        font-size: 2rem;
        margin: 2rem 0 4rem;
        justify-items: center;
    }

    &__total {
        font-family: $ff-primary;
        font-size: 1rem;
    }

    &__title-icon {
        color: $co-green;
        width: 4rem !important;
        height: auto !important;
    }

    &__tracks {
        display: -ms-grid;
        display: grid;
        gap: 1rem 2rem;
        grid-template-columns: repeat(4, 1fr);

        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(1, 1fr);
        }

        &.is-loading {
            margin-top: -4rem;
            height: 4rem;
        }
    }

    &__track {
        display: -ms-grid;
        display: grid;
        width: 100%;
        padding-bottom: 1rem;
        position: relative;
        grid-template-columns: 50px 1fr 50px;
        border-bottom: 1px solid;

        &:hover {
            #{$this}__song-title button {
                color: $co-green-2;

                &.no-preview {
                    color: $co-green;
                }
            }

            #{$this}__number {
                color: $co-green-2;
            }

            #{$this}__play-overlay {
                opacity: 1;
            }

            #{$this}__play-icon {
                opacity: 1;
            }
        }
    }

    &__number {
        @include transition;
        color: $co-green;
        text-align: center;
        font-size: 40px;
        line-height: 35px;
        font-weight: bold;

        &.is-small {
            font-size: 29px;
        }
    }

    &__song {
        display: flex;
        align-items: flex-start;
        padding-left: 1rem;
        gap: 1rem;
    }

    &__album {
        position: relative;
        height: 70px;
    }

    &__portrait {
        width: 70px;
        border-radius: 5px;
    }

    &__play-overlay {
        @include transition;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;
    }

    &__play-icon {
        @include transition;
        width: 36px;
        height: 36px;
        top: 17px;
        left: 17px;
        position: absolute;
        cursor: pointer;
        opacity: 0;

        img {
            border-radius: 5px;
            max-width: 100%;
        }
    }

    &__votes {
        font-size: 14px;
        color: $co-green-2;
        display: inline-flex;
        justify-content: flex-end;
        gap: 2px;
    }

    &__votes-icon {
        width: 18px !important;
        height: 18px !important;
    }

    &__song-name {
        width: 100%;
    }

    &__song-title {
        font-size: 14px;
        margin-bottom: 5px;

        button {
            border: 0;
            text-align: left;
            color: $co-green;
            padding: 0;
            background: none;
            font-weight: $fw-bold;
            cursor: pointer;

            &.no-preview {
                cursor: default;
            }
        }
    }

    &__song-artist {
        font-size: 12px;
        font-weight: 200;
    }

    &__voted {
        height: 18.2px !important;
    }

    &__more {
        justify-self: center;
        grid-column: 1 / 5;

        @include media-breakpoint-down(lg) {
            grid-column: 1 / 3;
        }
        
        @include media-breakpoint-down(sm) {
            grid-column: 1 / 2;
        }

        &.is-loading {
            color: $co-green;

            svg {
                color: $co-white;
            }

            &:after {
                height: 55px;
                width: 55px;
            }
        }
    }

    .c-music__track--vote {
        padding: 0.2rem 0;

        &.is-loading {
            height: 24.59px;
        }
    }
}