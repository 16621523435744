// GENERAL - WORKSPACE
$imagesUrl: "/assets";
$fontsUrl: "/assets/fonts";

// GENERAL - BREAKPOINTS
$grid-breakpoints: (
    xs: 0,
    sm: 481px,
    md: 651px,
    lg: 769px,
    xl: 1200px,
) !default;

// GENERAL - GUTTER:
$gutter: 20px;

// GENERAL - MAX-WIDTH CONTENT
$site-max-width: 1000;
$site-main-content-width-offset: calc($site-max-width/2);

// GENERAL - TRANSITIONS
// @include transition($tr-prop: all, $tr-time: $tr-time, $tr-method: ease-out);
$tr-time: .15s;
$tr-time-slow: 1.0s;

// FUENTES - FONT FAMILY
$ff-primary: 'RobotoMono', monospace;
$ff-secondary: 'Rockwell', 'Times New Roman', Georgia, serif;
$ff-tertiary: 'Allura', 'Times New Roman', Georgia, serif;

// FUENTES - FONT-WEIGHT:
// $fw-thin: 100;
$fw-extra-light: 200;
// $fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
// $fw-extra-bold: 800;
// $fw-black: 900;

// FUENTES - MEDIA QUERY CHANGE FONT SIZE _tools.mixins.scss (@mixin text)
$mq: "md";  // xs, sm, md, lg, xl

// FUENTES - HOME MAIN ARTICLE
$fs-title-format-1: 44;
$fs-title-format-1-mb: 30;

// COLOR - COLORES DEL SITE
$co-primary: #0E6C67;              //OK: Color corporativo principal
$co-secondary: #EBFAFF;            //OK: Color corporativo secundario
$co-tertiary: #58BEBC;             //OK: Color corporativo terciario
$co-quaternary: #e6f0f0; 
$co-primary-light: $co-secondary;
$co-primary-text: $co-primary;
$co-primary-bg: $co-primary;
$co-primary-bg-2: $co-primary;

// COLOR - COLORES GENÉRICOS
$co-white: #ffffff;               //OK: Blanco genérico

$co-grey-1: #F3F3F3;              //OK: Fondo gris genérico
$co-grey-2: #c1c1c1;              //OK: Icon related new, border
$co-grey-3: #8e8e8e;              //OK: Social icons live, author
$co-grey-4: rgb(71, 85, 105);              //OK: Text intro, subtitle
$co-bg-grey: $co-grey-1;              //Gris de fondo genérico. De esta manera se puede referir al "$co-bg-grey" de forma genérica

$co-black: rgb(90, 118, 117);
$co-full-black: #000000;          //OK: Negro puro

$co-green: $co-primary;
$co-green-2: $co-tertiary;
$co-green-3: $co-quaternary;             //OK: Se utiliza para los borders y mensaje correcto del form
$co-green-4: #92b500;             //OK: Todavía no tiene uso especificado
$co-red: #ff7777;                 //OK: Se utiliza para los borders y mensaje error del form
$co-red-2: #ffaaaa;               //OK: Se utiliza para el placeholder y mensaje de error del form
$co-red-3: #fff5f5;               //OK: Se utiliza para el background error del form
$co-yellow: #efdc00;              //OK: Todavía no tiene uso especificado
$co-blue: #eaf2f4;                //OK: Todavía no tiene uso especificado

// COLOR - ASIGNAR COLORES A TEXTOS
$co-title: $co-black;
$co-text: $co-title;
$co-text-light: $co-grey-2;
$co-subtitle: $co-grey-4;
$co-author: $co-grey-3;
$co-link: $co-primary;

// COLOR - ASIGNAR COLORES A BORDER, ACCIONES, ETC
$co-border: #e3e3e3;
$co-default-scroll: $co-primary;
$co-bg-banner: $co-grey-1;
$co-sponsored: $co-grey-1;

// COLOR - DEGRADADOS:
$co-bg-gradient-1: linear-gradient(to bottom, rgba($co-black, 0.6) 0%, rgba($co-black, 0) 100%);
$co-bg-gradient-2: linear-gradient(to bottom, rgba($co-black, 0) 0%, rgba($co-black, 0.6) 100%);

// COLOR - BOX-SHADOW:
$box-shadow: 0 5px 5px rgba($co-black, 0.1);
$box-shadow-2: 0 0px 5px rgba($co-black, 0.1);
$box-shadow-bottom: 0px 10px 10px -10px rgba(0,0,0,.2);

// MICROCOMPONENTS - BUTTON (.m-button)
$ff-m-button: $ff-primary;
$fs-m-button: 14;
$fw-m-button: $fw-semi-bold;
$co-m-button-primary: $co-primary;
$co-m-button-secondary: $co-secondary;
$co-m-button-tertiary: $co-primary;


//#MICROCOMPONENTS - LOGO (.m-logo)
$landing-m-logo-width: 50%;
$landing-m-logo-width-md: 70%;
$landing-m-logo-width-sm: 55%;

$full-m-logo-width: 337;
$full-m-logo-height: 59;

$normal-m-logo-width: 180;
$normal-m-logo-height: 25;

$reduced-m-logo-width: 170;
$reduced-m-logo-height: 23;

$footer-m-logo-width: 170;
$footer-m-logo-height: 23;