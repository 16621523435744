/* ========================================================================================= */
/* MICRO COMPONENTS - FORM                                                                   */
/* ========================================================================================= */

.c-form {
    $this: &;

    width: 100%;
    -ms-display: grid;
    display: grid;

    &__container {
        width: 100%;
        margin: 1.3rem 0;
        position: relative;

        &.has-icon {
            #{$this}__input {
                margin: 0 0 0.5rem 3rem;
                width: calc(100% - 3rem);
            }

            #{$this}__label {
                margin-left: 3rem;
            }
        }
    }
    &__icon {
        position: absolute;
        font-size: 2rem !important;
    }
    &__input {
        @include transition;
        width: 100%;
        background: none;
        border: 0;
        border-bottom: 1px solid #ced4da;
        padding: 0.5rem 0 0.7rem 0;
        font-size: 1.3rem;

        &--active {
            border-bottom: 1px solid $co-primary;
            box-shadow: 0 1px 0 0 $co-primary;
        }
    }
    &__label {
        @include transition;
        position: absolute;
        top: 0.3rem;
        left: 0;
        font-size: 1.3rem;
        cursor: text;

        &--active {
            color: $co-primary;
            transform: translateY(rem(-20));
            font-size: 0.8rem !important;
            cursor: default;
        }

        @include media-breakpoint-down(xs) {
            font-size: 1.1rem;
        }
    }
    &__submit {
        width: 100%;
        margin: 1.5rem 0;
        background: $co-primary;
    }
    &__error {
        margin-left: 3rem;
        color: $co-red;
        text-align: left;
    }
}