.c-home {
    $this: &;
    
    &__location {
        display: -ms-grid;
        display: grid;

        .o-container {
            grid-template-columns: repeat(3, 1fr);
            z-index: 1;
            text-align: center;

            @include media-breakpoint-down(xs) {
                grid-template-columns: auto;
                grid-template-rows: 1.5fr repeat(3, 1fr);

                .c-home__title {
                    grid-column: auto;
                }
            }
        }

        &-icon {
            height: 4rem !important;
            width: auto !important;
            color: $co-green;
        }

        &-image {
            background: url(#{$imagesUrl}/images/masdelsord.jpg);
            background-size: cover;
            background-position-x: center;
            background-position-y: 95%;
            width: 100vw;
            height: 40vw;
            margin-top: 3rem;

            @include media-breakpoint-down(sm) {
                height: 40vh;
            }
        }

        #{$this}__title {
            grid-row: 1/2;
            grid-column: 1/5;
        }
    }
}