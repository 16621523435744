.c-home {
    $this: &;

    &__playlist {
        background: url(#{$imagesUrl}/images/playlist-bg.png) repeat $co-green;

        &--image {
            width: 100%;
        }

        &--title {
            text-transform: uppercase;
            color: $co-white;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            margin-left: -10px;
            font-size: 1.5rem;

            @include media-breakpoint-down(sm) {
                font-size: 1rem;
                margin-bottom: 0.5rem;
            }
        }

        .o-container {
            grid-template-columns: 2fr 1fr;
        }

        .m-button {
            background: $co-green-2;
            color: $co-white;
        }
    }
}