.m-loading {
    &--landing {
        max-width: 30rem;
        margin: auto;
    
        &__dates {
            display: -ms-grid;
            display: grid;
            grid-template-columns: repeat(2, 50%);
            
            span:last-child {
                text-align: right;
            }
        }
        &__bar {
            background: $co-grey-1;
            height: rem(28);
            border-radius: rem(100);
            display: -ms-grid;
            display: grid;
            align-items: center;
            position: relative;
            margin-top: 10px;
            overflow: hidden;
        }
        &__percent {
            color: $co-grey-4;
            text-align: center;
            z-index: 2;
            font-weight: $fw-medium;
        }
        &__fill {
            @include transition;
            background: $co-green-2;
            z-index: 1;
            height: 100%;
            position: absolute;
        }
    }
}