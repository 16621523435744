.c-rsvp {
    &__header {
        background: url('#{$imagesUrl}/images/rsvp.jpg') no-repeat center;
        background-size: cover;
        padding-top: 44px;
    }

    &__title {
        font-family: $ff-tertiary;
        font-size: 3rem;
        font-weight: bold;
        color: $co-white;
        text-align: center;
        margin: rem(80) 0;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: rem(25);
            background: url('#{$imagesUrl}/images/line-white.svg') no-repeat center;
            margin: rem(12) 0 0;
        }
    }

    &__attendees {
        display: -ms-grid;
        display: grid;
        justify-items: center;
        margin: 3rem 0;
        text-align: center;

        &-icon {
            width: 4rem !important;
            height: auto !important;
            color: $co-green;
        }

        &-title {
            font-family: $ff-secondary;
            font-size: 2rem;
        }
    }

    &__info {
        margin-bottom: 3rem;

        p {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__users {
        display: -ms-grid;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-bottom: 3rem;

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
        }
    }

    &__user {
        padding: 3rem;
        background: $co-grey-1;

        &-name {
            font-family: $ff-secondary;
            font-size: 2rem;
            font-weight: bold;
            color: $co-green;
            margin-bottom: 1rem;
        }

        .Mui-checked {
            color: $co-green !important;
        }

        &-me {
            grid-row: 1;
            grid-column: 1;
        }
    }

    &__confirmation {
        margin-bottom: 1rem;
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row: 1;
        
        &-title {
            grid-column: 1/3;
            margin-right: 1rem;
        }
    }
    
    &__intolerances {
        margin-top: 2rem;
        display: -ms-grid;
        display: grid;
        grid-row: 2;

        &.hide {
            display: none;
        }
    } 

    &__intolerance-other {
        margin-top: 2rem;
        
        .c-form{
            &__input {
                font-size: 1rem;
                color: $co-text;
            }
            &__label {
                font-size: 1rem;
                color: $co-text;
            }
        }
    }

    .c-form__submit {
        margin: 1rem 0 0;
        grid-row: 3;
    }

    &__feedback {
        margin-top: 2rem;
        color: $co-green;
        grid-row: 4;
    }

    .MuiFormGroup-root {
        display: -ms-grid;
        display: grid;
    }

    .MuiSwitch-track {
        background-color: $co-green !important;
    }

    .MuiFormControlLabel-label {
        font-family: $ff-primary;
    }

    .Mui-checked {
        color: $co-green !important;
    }

}