.m-button {

    $this: &;

    @include transition;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(10);
    width: fit-content;
    padding: rem(10) rem(13);
    border: none;
    color: $co-white;
    background-color: $co-m-button-primary;
    font-family: $ff-m-button;
    font-weight: $fw-m-button;
    font-size: rem($fs-m-button);
    text-transform: uppercase;
    line-height: 1.3;
    text-align: center;
    text-decoration: none;

    &:hover {
        @include transition;
        opacity: 0.7;
        color: $co-white;
        cursor: pointer;
    }

    &__icon {
        fill: $co-white;
    }

    //MODIFICADORS
    &--primary {
    }
    &--secondary {
        color: $co-m-button-tertiary;
        background-color: transparent;
        border: 1px solid;
        #{$this}__icon {
            fill: $co-m-button-tertiary;
        }
    }
    &--small {
        padding: rem(8) rem(10);
        font-size: rem(12);
        gap: rem(8);
    }
}
