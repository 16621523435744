.c-hacker {
    padding-top: 44px;
    width: 100%;
    height: max(100vh, 100%);
    background: #272822;

    &__input {
        padding: 10px 1rem;
        width: 100%;
        color: #bbb;
        font-size: 1.2em;
        border: none;
        overflow: auto;
        background: #007de3;

		&::-webkit-input-placeholder {
            color: #bbb;
        }
    }

    &__result {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem;
        height: calc(100vh - 86px);
        overflow-y: auto;
        align-content: flex-start;
    }

    &__item {
        max-width: 100%;
    }

    &__modal-img {
        max-width: 100%;
        margin: 1rem 0;
    }

    .c-menu {
        background: #111;
    }

    .c-menu__logo {
        width: 267px;

        @include media-breakpoint-down(sm) {
            width: 200px;
        }

        &-image{
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            width: 267px;
            height: 26px;
            margin-top: 5px;
            background: url(#{$imagesUrl}/logos/logo-hacker.png) no-repeat 100%;
            background-size: contain;

            @include media-breakpoint-down(sm) {
                width: 200px;
                height: 20px;
                margin-top: 8px;
            }
        }
    }
}