// @include load-font($name, $file, $weight: null, $style: null, $exts: eot woff2 woff ttf svg);

// RobotoMono
@include load-font('RobotoMono', 'RobotoMono-ExtraLight', 200, regular, $exts: woff woff2);
@include load-font('RobotoMono', 'RobotoMono-Regular', 400, regular, $exts: woff woff2);
@include load-font('RobotoMono', 'RobotoMono-Medium', 500, regular, $exts: woff woff2);
@include load-font('RobotoMono', 'RobotoMono-SemiBold', 600, regular, $exts: woff woff2);

// Rockwell
@include load-font('Rockwell', 'Rockwell', 400, regular, $exts: woff woff2);

// Allura
@include load-font('Allura', 'Allura-Regular', 500, regular, $exts: woff woff2);