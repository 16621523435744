.c-home {
    &__intro {
        display: -ms-grid;
        display: grid;
        align-items: end;
        justify-items: center;
        position: relative;
        background: url(#{$imagesUrl}/images/home.jpg) no-repeat 70% 30%;
        background-size: cover;
        grid-template-rows: 1.5fr 0fr 1fr;
        height: 100vh;
        width: 100%;

        &--logo {
            width: 50%;
            max-width: rem(280);
            min-width: rem(150);
            margin-top: 2rem;
        }

        &--arrow {
            cursor: pointer;
            color: $co-white;
            margin-bottom: 1rem;
            width: 2rem !important;
            height: 2rem !important;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .m-loading--landing {
        max-width: rem(450);
        width: 70%;

        &__dates {
            margin-top: 3vw;
            color: $co-white
        }
    }
}