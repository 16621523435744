.o-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 1rem;
    display: -ms-grid;
    display: grid;
    align-items: center;
    justify-items: center;

    &--full {
        width: 100vw;
        max-width: 100vw;
    }
}