.c-admin {
    &__header {
        background: $co-primary;
        padding-top: 44px;
    }

    &__title {
        font-family: $ff-secondary;
        font-size: 3rem;
        font-weight: bold;
        color: $co-white;
        text-align: center;
        margin: rem(40) 0 0;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
    }

    &__section {
        font-size: 2rem;
        color: $co-tertiary;
        margin-bottom: rem(40);

        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
    }

    &__icon {
        font-size: 3rem !important;
        margin: rem(-10);
    }

    &__content {
        margin: 3rem auto;
        padding: 0 3rem;

        @include media-breakpoint-down(sm) {
            padding: 0 1rem;
        }
    }

    &__subtitle {
        font-size: 2rem;
        margin-bottom: 1rem;

        &-icon {
            font-size: 2rem !important;
            margin: 0 rem(10) rem(-6) 0;
        }

        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;

            &-icon {
                font-size: 1.5rem !important;
            }
        }
    }

    &__users {
        margin-bottom: 5rem;
    }

    &__track-column {
        max-width: 200px;
    }

    .MuiPaper-root {
        box-shadow: none;
    }

    .MuiTableCell-head {
        font-weight: bold;
        color: $co-green;
    }

    .Mui-active {
        color: $co-tertiary !important;
    }
}