.c-landing {
    &__container {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        position: fixed;

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }
    &__left {
        display: -ms-grid;
        display: grid;
        justify-items: stretch;
        align-items: center;
        font-weight: $fw-regular;

        &-container {
            margin: auto rem(30);
        }
    }
    &__right {
        background: url(#{$imagesUrl}/images/landing.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }
    &__login {
        display: -ms-grid;
        display: grid;
        justify-content: center;
        margin-top: rem(45);

        @include media-breakpoint-down(sm) {
            margin-top: rem(30);
        }
    }

    .m-logo--landing {
        text-align: center;
        margin-bottom: rem(45);

        @include media-breakpoint-down(sm) {
            margin-bottom: rem(30);
            margin-top: rem(20);
        }
    }
}