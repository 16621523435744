.c-home {
    &__story {
        display: -ms-grid;
        display: grid;
    }
    
    &__chapter {
        $this: &;
        width: 100vw;
        font-weight: $fw-extra-light;
        position: relative;
        display: -ms-grid;
        display: grid;
        justify-items: center;
        background: url(#{$imagesUrl}/images/stars.svg) repeat;
        background-size: 40%;
        overflow: hidden;
        grid-template-columns: 3fr 2fr;

        @include media-breakpoint-down(sm) {
            background-size: 60%;
            grid-template-columns: 1fr;
        }

        .o-container {
            padding: 3rem;
            justify-items: left;
            max-width: calc(min(100vw, 900px) - 300px);

            @include media-breakpoint-down(sm) {
                grid-template-columns: auto;
                max-width: 100%;
            }
        }

        &-title {
            font-family: $ff-secondary;
            font-size: rem(24);
            margin-bottom: rem(24);
            color: $co-primary;
            grid-row: 1/2;
            grid-column: 1/2;
            background: $co-white;

            @include media-breakpoint-down(sm) {
                grid-column: 1/2 !important;
            }
        }

        &-content {
            text-align: left;
            z-index: 1;
            grid-row: 2/3;
            grid-column: 1/2;
            background: $co-white;
            
            p {
                margin-bottom: rem(24);
            }

            @include media-breakpoint-down(sm) {
                grid-row: 2/3 !important;
                grid-column: 1/2 !important;
            }
        }

        &-page {
            grid-column: 1/2;
            background: $co-white;

            @include media-breakpoint-down(sm) {
                grid-column: 1/2 !important;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            grid-row: 1/3;
            grid-column: 2/3;
            box-shadow: rem(-6) 0 2rem darken($co-grey-1, 10%);

            @include media-breakpoint-down(sm) {
                height: 40vh;
                grid-row: 3/4 !important;
                grid-column: 1/2 !important;
            }
        }

        &-odd {
            background: url(#{$imagesUrl}/images/stars-green.svg) repeat;
            background-color: $co-green;
            grid-template-columns: 2fr 3fr;

            #{$this}-title {
                grid-column: 2 / 3;
                color: $co-white;
                background: $co-green;
            }

            #{$this}-content {
                grid-column: 2 / 3;
                color: lighten($co-tertiary, 15%);
                background: $co-green;
            }

            #{$this}-page {
                grid-column: 2 / 3;
                color: white;
                background: $co-green;
            }
            
            #{$this}-image {
                grid-column: 1 / 2;
                grid-row: 1/3;
                left: 0;
                box-shadow: rem(6) 0 2rem darken($co-green, 10%);
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: 1fr;
            }
        }

        &-0 {
            grid-template-columns: 1fr;
            padding-top: 0;

            .o-container {
                max-width: 900px;
                padding: 0 1rem 3rem;

                @include media-breakpoint-down(sm) {
                    padding: 0 3rem 3rem;
                }
            }
        }

        &-1 {
            #{$this}-image {
                background-position-x: 46%;
                background-position-y: top;
            }
        }

        &-2 {
            #{$this}-image {
                background-position-x: 20%;
                background-position-y: top;
            }
        }

        &-3 {
            #{$this}-image {
                background-position-x: 45%;
                background-position-y: top;
            }
        }

        &-4 {
            #{$this}-image {
                background-position-x: 39%;
                background-position-y: 9%;
            }
        }
            
        &-5 {
            #{$this}-image {
                background-position-x: 45%;
                background-position-y: 55%;
            }
        }

        &-6 {
            #{$this}-image {
                background-position-x: 74%;
                background-position-y: 80%;
            }
        }

        &-7 {
            #{$this}-image {
                background-position-x: 18%;
                background-position-y: bottom;
            }
        }

        &-8 {
            #{$this}-image {
                background-position-x: 47%;
                background-position-y: 30%;
            }
        }

        &-9 {
            #{$this}-image {
                background-position-x: 45%;
                background-position-y: 70%;
            }
        }
        
        &-10 {
            #{$this}-image {
                background-position-y: 80%;
                background-position-x: 0%;
            }
        }
    }

    &__rsvp-button {
        margin-bottom: 1rem;

        svg {
            height: 1rem;
        }
    }
}