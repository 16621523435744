//NO SCROLL
html.no-scroll{
    overflow: hidden;

    &-mobile {
        overflow: auto;

        @include media-breakpoint-down(sm) {
            overflow: hidden;
        }
    }
}

//DESKTOP
@include media-breakpoint-up(sm) {
    .u-mobile {
        display: none;
    }
}

//MOBILE
@include media-breakpoint-down(xs) {
    .u-desktop {
        display: none;
    }
}

.is-loading {
    position: relative;
    opacity: 0.4;
    pointer-events: none;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        @include background("icons/icon-loading.svg", center, 100%);
    }
}
.is-disabled {
    opacity: .3;
    cursor: default;
    pointer-events: none;
}