// mixin de entidad (con parametros) que tire de este mixing genérico.

@mixin text($fs: null, $fs-mb: null, $co: null, $fw: null, $tt: null, $ma: null, $ff: null, $lh: null) {
    //font size
    @if $fs != null {
        font-size: rem($fs);
    }

    //font size mobile
    @if $fs-mb != null {
        @include media-breakpoint-down(md) {
            font-size: rem($fs-mb);
        }
    }

    //color
    @if $co != null {
        color: $co;
    }

    //margin
    @if $ma != null {
        margin: 0 0 rem($ma);
    }

    //text transform
    @if $tt != null {
        text-transform: $tt;
    }

    @if $fw != null {
        font-weight: $fw;
    }

    @if $ff != null {
        font-family: $ff;
    }

    //line height
    @if $lh != null {
        line-height: $lh;
    }
}

@mixin border($border, $color) {
    @if ($border) {
        border-width: $border;
        border-style: solid;
        border-color: $color;
    }
}

//* BACKGROUND IMAGE * //

@mixin background($imgpath, $position: center, $size: null, $repeat: no-repeat) {
    background: {
        image: url($imagesUrl + "/" + $imgpath);
        position: $position;
        size: $size;
        repeat: $repeat;
    }
}

//* END BACKGROUND IMAGE * //

// LETTER-SPACING FROM ADOBE XD TO EM //

@mixin letter-spacing($spacing) {
    letter-spacing: ($spacing / 1000) * 1em;
}

// END LETTER-SPACING FROM ADOBE XD TO EM //

@mixin transition($tr-prop: all, $tr-time: $tr-time, $tr-method: ease-out) {
    // TODO: Repasar que el header haga bien la transición
    // body.is-loaded & {
    transition: $tr-prop $tr-time $tr-method;
    // }
}

// after / before
// ----------------------------------------------------------------------------
// ex:
// @include afterBefore(before, 13px, 13px, left, 0, top, 2px, #EF2D2C);
@mixin afterBefore($element_position, $width, $height, $y, $y_pos, $x, $x_pos, $bg_color, $position: absolute) {
    position: relative;
    &::#{$element_position} {
        content: "";
        display: block;
        position: $position;
        width: $width;
        height: $height;
        background: $bg_color;
        #{$y}: $y_pos;
        #{$x}: $x_pos;
    }
}

// =============================================================================
// Font Face
// =============================================================================
@mixin load-font($name, $file, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;
    $path: "#{$fontsUrl}/#{$file}";
    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_"),
    );
    $formats: (
        otf: "opentype",
        ttf: "truetype",
    );
    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }
    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-display: swap;
        font-weight: $weight;
        src: $src;
    }
}

// MIXIN PARA BODY DE CKEDITOR
// @mixin editor-tags($fs, $co-link) { 
// del $fs dependerían los h's
@mixin editor-tags() { 
    @include text(
        $fs-item-viewer-text,
        $fs-item-viewer-text-mob,
        $co: $co-item-viewer-text,
        $fw: $fw-item-viewer-text,
        $tt: $tt-item-viewer-text,
        $ma: $ma-item-viewer-text,
        $ff: $ff-item-viewer-text
    );
    line-height: 1.5em;
    p,
    ul,
    ol {
        @include text(
            $fs-item-viewer-text,
            $fs-item-viewer-text-mob,
            $co: $co-item-viewer-text,
            $fw: $fw-item-viewer-text,
            $tt: $tt-item-viewer-text,
            $ma: $ma-item-viewer-text,
            $ff: $ff-item-viewer-text,
            $lh: 1.5em
        );
    }

    ul,
    ol {
        list-style: none;
        margin: 0 0 20px 0;
        padding: 0 0 0 18px;

        & li::before {
            content: "\2022";
            color: $co-primary;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }

    sub,
    sup {
        font-size: rem(12);
    }

    li {
        margin-bottom: 5px;
        line-height: inherit;
    }

    a {
        color: $co-article-viewer-link;
        font-weight: $fw-article-viewer-link;
        @include hover;
    }
    h2 {
        @include text(26, 23, $fw: $fw-semi-bold, $ma: 5);
    }
    h3 {
        @include text(24, 21, $fw: $fw-semi-bold, $ma: 5);
    }
    h4 {
        @include text(22, 19, $fw: $fw-semi-bold, $ma: 5);
    }

    strong,
    b {
        color: $co-article-viewer-bold;
        font-weight: $fw-article-viewer-bold;
    }

    blockquote {
        p {
            @include border($blockquote-border, $co-blockquote-border);
            @include text($fs-blockquote, $fs-blockquote-mob, $co: $co-blockquote, $fw: $fw-bold, $ff: $ff-blockquote, $ma: $gutter, $lh: 1.3);
            @if ($blockquote-comillas == true) {
                &:before {
                    content: "“";
                }
                &:after {
                    content: "”";
                }
            }
            float: left;
            padding: 0 0 $gutter/1.4 0;
            width: $wd-blockquote;
            margin: 0;
        }
    }
}
@mixin hover($underline: null) { 
    @if ($underline != null) {
        &:hover {
            text-decoration: underline;
        }
    } @else {
        display: inline;
        border-bottom: 1px solid transparent;
        @include transition($tr-method: ease-in-out);
        &:hover {
            border-bottom: 1px solid;
        }
    }
}