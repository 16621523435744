.c-player {
    height: rem(75);
    background: #a0a0a0;
    box-shadow: 0px 0px 24px 6px rgb(4 4 4 / 20%);
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
    display: none;
    grid-template-columns: 1fr 3fr;

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr 2fr;
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 0.8fr 1fr;
    }

    @include media-breakpoint-down(xs) {
        grid-template-columns: 75px 1fr;
    }

    &__track-info {
        position: relative;
        height: 75px;
        background: $co-green;
        background: linear-gradient(90deg, rgba(lighten($co-green, 5%), 1) 0%, rgba(darken($co-green, 5%), 1) 100%);
        border-radius: 0 rem(12) rem(12) 0;
        box-shadow: 0px 0px 24px 6px rgb(4 4 4 / 20%);
        display: -ms-grid;
        display: grid;
        grid-template-columns: 50px 1fr;
        align-items: center;
        gap: rem(11);
        padding: rem(12.5);
        z-index: 1;

        @include media-breakpoint-down(xs) {
            gap: 0;
            border-radius: 0;
        }
    }

    &__image {
        border-radius: rem(5);
        display: block;
        height: 50px;
        width: 50px;
    }

    &__info-text {
        display: flex;
        width: 100%;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &__title {
        font-weight: 700;
        margin-bottom: 4px;
        color: $co-white;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
    }

    &__artist {
        font-weight: 300;
        margin-top: 0;
        color: $co-white;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
    }

    &__controls {
        overflow: hidden;
        width: calc(100% + 10px);
        margin-left: -10px;
        position: relative;
        display: -ms-grid;
        display: grid;
    }

    &__controls--background {
        height: calc(100% + 2rem);
        filter: blur(0.5rem);
        background-size: cover;
        background-position: 50%;
        margin: -1rem;
        opacity: 0.4;
        z-index: -1;
        position: absolute;
        top: 0;
        width: calc(100% + 2rem);
    }

    &__controls--buttons {
        padding: rem(17.5) rem(17.5) rem(17.5) calc(rem(17.5) + 10px);
        height: 100%;
        display: -ms-grid;
        display: grid;
        gap: rem(17.5);
        grid-template-columns: 1fr 6fr 1fr;
        align-items: center;
        justify-items: center;

        @include media-breakpoint-down(md) {
            grid-template-columns: 40px 1fr 24px;
        }
    
        @include media-breakpoint-down(sm) {
            grid-template-columns: 40px 1fr 24px;
        }
    }

    &__controls--button {
        @include transition;
        padding: 0;
        border: 0;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background: none;
        cursor: pointer;

        &:hover {
            background: rgba($co-white, 0.4);
        }
    }

    &__controls--progress {
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: $co-white;
        font-size: 11px;
        align-self: end;
        width: 100%;
    }

    &__controls--progress-duration {
        text-align: right;
    }

    &__controls--progress-bar {
        grid-column: 1 / 3;
        transition: background 0.2s ease;
        cursor: pointer;
        width: 100%;
        margin: 6px 0;
        background-color: transparent;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-runnable-track {
            border: 0px solid rgba(0, 0, 0, 0);
            box-shadow: rgba($co-white, 0.2) 0 0 10px 4px;
            border: 0;
            width: 100%;
            height: 3px;
            cursor: pointer;
        }

        &::-webkit-slider-thumb {
            margin-top: -6px;
            width: 15px;
            height: 15px;
            background: $co-white;
            box-shadow: rgba($co-white, 0.2) 0 0 10px 4px;
            border: 0px solid rgba(0, 0, 0, 0);
            border: 0;
            border-radius: 100%;
            cursor: pointer;
            -webkit-appearance: none;
        }

        &::-moz-range-track {
            border: 0px solid rgba(0, 0, 0, 0);
            border: 0;
            width: 100%;
            height: 3px;
            cursor: pointer;
        }

        &::-moz-range-thumb {
            width: 15px;
            height: 15px;
            background: $co-white;
            box-shadow:rgba($co-white, 0.4) 0 0 10px 4px;
            border: 0px solid rgba(0, 0, 0, 0);
            border: 0;
            border-radius: 100%;
            cursor: pointer;
        }

        &::-ms-track {
            background: transparent;
            border-color: transparent;
            border-width: 14.8px 0;
            color: transparent;
            width: 100%;
            height: 3px;
            cursor: pointer;
        }

        &::-ms-fill-lower {
            border: 0px solid rgba(0, 0, 0, 0);
            border: 0;
        }

        &::-ms-fill-upper {
            border: 0px solid rgba(0, 0, 0, 0);
            border: 0;
        }

        &::-ms-thumb {
            width: 15px;
            height: 15px;
            background: $co-white;
            border: 0px solid rgba(0, 0, 0, 0);
            border: 0;
            border-radius: 100%;
            cursor: pointer;
            margin-top: 0px;
        }
    }

    &__close {
        height: 24px;
    }

    &__close-button {
        @include transition;
        border: 0;
        padding: 0;
        background: none;
        color: $co-white;

        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }

    @supports (-ms-ime-align:auto) {
        .c-player__controls--progress-bar {
            margin: 0;
        }
    }
}